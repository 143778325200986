<template>
  <svg>
    <defs>
      <path id="prefix__a" d="M0 0H22V27.036H0z" />
      <path id="prefix__c" d="M0.011 0.014L1.311 0.014 1.311 0.424 0.011 0.424z" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <mask id="prefix__b" fill="#fff">
        <use xlink:href="#prefix__a" />
      </mask>
      <use fill="#FFF" xlink:href="#prefix__a" />
      <g mask="url(#prefix__b)">
        <g>
          <path
            fill="#8F1414"
            d="M15.984 19.724l.416.623 1.038 6.574.519 3.218s.795 4.843.865 5.328c0 0 .692 4.151.76 4.532 0 0 .624 3.806.658 4.186 0 0 .692 3.98.727 4.29 0 0 .588 3.53.657 4.049 0 0 .657 4.36.727 4.74l.9 5.432s-4.43.622-5.675.484c0 0-.277-.484-.311-.969 0 0 .207-5.397.034-7.058 0 0-.173-1.972-.45-2.283l-.069.034-1.038-3.425-.276-.727s.657-2.145.691-2.387c0 0 .658-2.837.692-3.979 0 0 .208-2.63.07-3.978 0 0-.277-2.803-.554-3.91 0 0-.554-1.695-.588-1.868 0 0-1.073-2.56-1.315-2.941l-.726-1.211.276-.796-.242.035s.692-2.872.761-3.149c.07-.277.416-1.28.416-1.28l1.453-1.384-.416-2.18z"
            transform="translate(0 3.3)"
          />
          <path
            fill="#6C0F0E"
            d="M6.366 19.794l-.38 1.868 1.349 1.626.657 4.532-.242-.103.035.657.173.104s-1.073 2.56-1.108 2.837l-.795.242s-.692-3.356-.761-3.529l.692-7.785.38-.45z"
            transform="translate(0 3.3)"
          />
          <path
            fill="#E22A2B"
            d="M10.276 14.673s.069 0-.45.138c0 0-2.284.554-2.63.623 0 0-.83.173-.934.242 0 0-.346.243-.588.554 0 0-1.73 2.214-2.283 3.01L.31 23.91s.52-.172 1.384.07c0 0 .761.104.97.311l3.701-4.497-.38 1.868 1.28 1.73.692 4.394v.692s-.83 1.28-.935 1.626v.07s.658.414.762.656c0 0 .242.243-.07.346 0 0-.173.035-.346-.103l-.553-.416-.104-.034v.034s.657 1.038.692 1.211c0 0 .138.277-.277.104l-.242-.104s.208.485-.173.416c0 0-.277-.208-.415-.381l-.104.346.312.45s.103.346-.208.242c0 0-.242-.104-.277-.173 0 0-.623 2.56-.657 4.775 0 0-.035 2.906.277 4.601 0 0 .622 3.771 1.141 5.467l.623 1.868s-.865 3.149-.9 3.39v.035s1.004.208 2.076.277l1.246.035s-.035-1.107-.242-2.318c0 0-.242-1.488-.242-2.457 0 0 .034-.934.173-1.522 0 0 .311-1.765.38-2.526 0 0 .38-2.56.415-2.837 0 0 .346-2.491.416-3.045 0 0 .415-2.906.415-3.044 0 0 .346 1.349.588 3.148 0 0 .346 2.526.415 2.906l.38 2.319s.312 1.833.416 2.248c0 0 .415 2.146.45 2.388 0 0 .138.83.138 1.626l.07 3.321s.864 0 1.522-.138c0 0 1.556-.346 1.66-.38 0 0-1.141-3.807-1.21-4.014 0 0-.14-.208 0-.52 0 0 .76-2.525.864-3.251 0 0 .588-2.422.554-5.329 0 0-.139-3.875-.692-5.535 0 0-.45-1.453-.52-1.626 0 0-.345.415-.656.38 0 0-.139-.034.034-.346 0 0 .208-.346.38-.484l-.31-.277s-.52.346-.762.346c0 0-.138-.138.07-.45 0 0-.381.173-.45.035 0 0-.139-.173.138-.45 0 0 .519-.519.796-.727l-.104-.207s-.623.657-.83.692c0 0-.485.207-.173-.38 0 0 .519-.623.76-.831l-.864-1.488.207-.761-.207.035.761-3.045.38-1.315 1.454-1.453-.416-2.18 1.142 1.765 1.972 2.94s.97-.622 1.972-.449c0 0 .173.07.208.104l-2.975-5.536s-1.488-2.318-1.696-2.526c0 0-.207-.38-.796-.45 0 0-.622-.172-.83-.242l-1.799-.726-2.906.069z"
            transform="translate(0 3.3)"
          />
          <path
            fill="#32363A"
            d="M18.752 24.603s.346-.415 1.35-.658c0 0 1.141-.173 1.384.173l-2.284 3.322-2.768 3.979-.346.45-.553.242-.277-.243-.173-.657.277-.865 2.18-3.702 1.21-2.041z"
            transform="translate(0 3.3)"
          />
          <path
            fill="#2F3235"
            d="M0 24.084s.208-.277.934-.277c0 0 1.315.035 1.765.519l2.617 3.746 1.292 1.894.346.345-.276.208-.173.242-.312.277h-.588l-3.01-3.737L0 24.084z"
            transform="translate(0 3.3)"
          />
          <path
            fill="#761010"
            d="M8.561 16.8c.015.066.061.122.125.145.065.024.165.046.31.046h5.13c.018 0 .035-.002.052-.006.084-.023.343-.108.488-.334l.008-.012.506-.653c.12-.153-.027-.369-.214-.315-.013.003-.026.009-.038.015l-1.188.627c-.026.014-.054.021-.083.023l-1.986.1h-.022l-1.16-.067H9.396c-.036 0-.07-.01-.101-.028l-.562-.33c-.15-.088-.334.046-.297.217l.125.572z"
            transform="translate(0 3.3)"
          />
          <path
            fill="#A81717"
            d="M10.276 14.673s-1.28.346-2.145.519c0 0-.277.035-.035.623 0 0 .242 1.072.554 1.072l5.155.035c.069 0 .553.07.83-.346 0 0 .77-.995.921-1.086l-2.374-.886-2.906.069z"
            transform="translate(0 3.3)"
          />
          <path
            fill="#000"
            d="M14.012 18.756l-2.733.38-.967.173h-.003c-.038.005-.87.113-1.106.485 0 0-.377.445-.21.858l.006.012c.032.05.278.436.306.534.002.008.002.017 0 .026-.01.056-.068.307-.344.369 0 0-.401.067-.415.266 0 .007 0 .014.003.02l.164.592c.006.02.02.037.04.043.034.01.095.016.208-.006.035-.007.07.017.074.052.006.04.016.087.038.117.012.015.03.023.049.023.08-.001.35-.005.496-.035 0 0 .104 0 .173-.207l.06-.208c.006-.021.023-.038.044-.043.2-.05 1.205-.298 1.346-.303h.008l.198-.034H11.687l.014-.002.603-.134c.01-.002.02-.007.028-.014.023-.021.064-.074.089-.197 0 0 .094-.661.223-.872.01-.017.03-.028.05-.029l.514-.032c.007 0 .014-.002.02-.005.041-.017.178-.106.265-.515"
            transform="translate(0 3.3)"
          />
          <path
            fill="#810A08"
            d="M12.11 19.136l1.66-.277s.242-.103.208.07l-.139.553s-.034 0-.242.035l-.657.069-.139.346.658-.07s0 .07-.035.208l-.138.38-.035.07-.623.035s-.242.519-.276.9c0 0 0 .207-.139.241l-.657.173s-.07.035 0-.276c0 0 .45-1.488.657-1.834v-.035h-.242l.138-.588z"
            transform="translate(0 3.3)"
          />
          <path
            fill="#871B19"
            d="M10.414 19.378l1.66-.242-.172.658-.658.069-.172.38.657-.103s.034.034 0 .242l-.139.38s-.553.07-.691.07l-.139.45.796-.105s.034 0 0 .208c0 0-.07.242-.104.45 0 0-.553.034-.796.104l-.692.207s-.2-.034-.117-.155l.218-.53.107-.56.346-.865h-.242l.138-.658z"
            transform="translate(0 3.3)"
          />
          <path
            fill="#86160E"
            d="M10.363 19.46l-.111.556c-.006.032-.031.056-.063.06-.121.018-.411.082-.399.29 0 .01.004.02.008.029.043.087.286.575.332.69.005.013.007.027.004.041-.014.081-.074.415-.135.536 0 0-.173.346-.208.484 0 0-.034.415-.173.45 0 0-.268.049-.436.044-.045-.001-.078-.042-.072-.086l.007-.043c.007-.055-.044-.1-.097-.084-.104.032-.245.06-.267-.004l-.135-.54c-.002-.01-.003-.022 0-.034.009-.04.046-.126.205-.153 0 0 .469-.1.517-.4.001-.01 0-.02-.002-.03-.019-.076-.111-.436-.232-.566-.005-.005-.009-.01-.012-.017-.039-.078-.255-.578.314-.992.002 0 .003-.002.005-.003.037-.023.516-.324.878-.318.047 0 .081.045.072.09"
            transform="translate(0 3.3)"
          />
          <path
            fill="#FFF"
            d="M10.31 19.448l-.138.519s-.45.069-.45.38c0 0 0 .104.242.554 0 0 .173.415-.034.726 0 0-.104.173-.173.277 0 0-.139.484-.139.588v.035l-.415.034.035-.31s-.277.137-.415.103l-.104-.52s.415-.103.588-.276c0 0 .242-.242.035-.588 0 0-.45-.588-.139-.934 0 0 .485-.588 1.107-.588"
            transform="translate(0 3.3)"
          />
          <path
            fill="#DC2829"
            d="M9.826 20.313s.173-.173.45-.208h.138l-.311.727-.277-.52z"
            transform="translate(0 3.3)"
          />
          <path
            fill="#DA2826"
            d="M11.314 19.932l.76-.104-.31.83-.139.416-.761.103.104-.276.622-.07s.208-.415.243-.657c0 0 .103-.138-.346-.07l-.277.036.104-.208z"
            transform="translate(0 3.3)"
          />
          <path
            fill="#000"
            d="M12.803 20.605c-.028-.038.028-.08.057-.044.16.199.693.972.737 1.135 0 0 .172.517-.308.623h-.006c-.067.004-1.192.054-1.796-.45 0 0 .76.415 1.452.347h.002c.035-.006.749-.122.38-.863l-.002-.005-.516-.743zM8.996 20.209s-.727-.692-.589-1.177c0 0 .104-.311.762-.276 0 0 1.003.138 1.557.484 0 0-.935-.38-1.523-.277 0 0-.484.07-.45.45 0 0 .07.484.243.796"
            transform="translate(0 3.3)"
          />
          <path
            fill="#FFF"
            d="M10.483 19.413l1.523-.208-.139.485-.657.069-.242.554.692-.07-.104.45-.761.07-.139.622.865-.138-.138.519s-1.211.138-1.419.242l.38-1.35.312-.726-.311.035.138-.554zM12.179 19.205l1.73-.311-.139.519-.934.104-.173.519.692-.104-.138.484-.727.07s-.242.9-.242 1.107l-.658.173s.416-1.384.554-1.73l.173-.38-.311.034.173-.485z"
            transform="translate(0 3.3)"
          />
          <path
            fill="#E22A2B"
            d="M13.736 19.517L12.974 19.621 12.871 19.897 13.666 19.794z"
            transform="translate(0 3.3)"
          />
          <g transform="translate(0 3.3) translate(10.76 11.21)">
            <mask id="prefix__d" fill="#fff">
              <use xlink:href="#prefix__c" />
            </mask>
            <path
              fill="#9A5456"
              d="M.011.284S.138.107.45.107c0 0 .277 0 .484-.07 0 0 .372-.064.377.02 0 0-.252.243-.505.32 0 0-.437.153-.795-.093"
              mask="url(#prefix__d)"
            />
          </g>
          <path
            fill="#F3A681"
            d="M6.885 5.47l.554-1.073 1.972-1.66 1.626-1.35 1.003-.346.727.242.969.243 1.626 2.49-.875 6.734s-.002.266-.498.884c0 0-.842 1.067-.876 1.101-.035.035-.692.796-1.488.9 0 0-.392.225-1.528-.458 0 0-1.897-1.376-2.485-2.275l-.554-4.394-.173-1.038z"
            transform="translate(0 3.3)"
          />
          <path
            fill="#382322"
            d="M10.241 13.254s-1.522-1.003-2.63-2.352c0 0-.23-1.69-.264-2.209 0 0-.138-3.252-.138-3.494 0 0 1.21-.07 2.11-.865 0 0 .727-.727 1.107-1.453 0 0 .312-.554.485-.658 0 0 .865-1.28 2.318-1.072 0 0-1.176-1.315-2.422-.554 0 0-2.221.444-2.605.705l-.015.006c-.139.028-2.022.442-2.622 2.074 0 0-.432.502-.64 3.72 0 0-.104 4.913-.104 5.155v1.142s-.034.346.208.346c0 0 1.176 0 2.041-.104"
            transform="translate(0 3.3)"
          />
          <path
            fill="#4C2F2C"
            d="M13.728 1.157c-.059-.017-.104-.063-.118-.123-.058-.255-.314-.917-1.402-.973 0 0-1.815-.332-3.403.555-.011.007-.022.014-.032.023L8.66.736c-.094.095-.528.556-.743 1.262 0 0-.64 1.886-.951 2.405 0 0-.416.726-.381.865 0 0 1.1-.035 1.724-.343l.011-.005c.064-.025.676-.266.87-.46.039-.037.056-.09.049-.143-.02-.135-.03-.542.352-1.256l.007-.014c.057-.14.945-2.281 1.346-2.415 0 0 .658-.45 1.557.034 0 0 .086.041.196.1.147.078.104.3-.062.316-.52.05-1.335.356-2.002 1.626 0 0-1.107 2.006-1.107 2.18l-.006.02c-.033.117.062.228.182.214.32-.037.946-.192 1.861-.786l.008-.004c.091-.052 1.727-.992 2.239-2.13l.014-.025c.07-.1.547-.827-.097-1.02"
            transform="translate(0 3.3)"
          />
          <path
            fill="#4C2F2C"
            d="M6.964 5.236s-.482 1.208-.447 2.212c0 0 .207 3.044.277 3.425 0 0 .276 2.249.276 2.56 0 0 .104.727.173.9 0 0 .208-.035.312-1.661 0 0-.035-1.868-.07-2.18 0 0-.275-4.526-.276-5.256h-.245z"
            transform="translate(0 3.3)"
          />
          <path
            fill="#382322"
            d="M13.874 1.22s1.315.311 2.007 1.21c0 0 .968.797 1.107 3.91 0 0 .173 3.426.173 4.394 0 .139.104 2.56-.07 2.803 0 0 .104.208-.864.138l-1.488-.138s-.07.484-.173.623c0 0-.173-.45-.173-.658l-1.488-.144.104-.623s1.107-1.309 1.315-1.724c0 0 .104-.207.104-.484 0 0 .103-1.107.103-1.315 0 0 0-.9.035-1.107 0 0 0-2.249-.138-3.148 0 0-.346-2.146-.623-2.768 0 0 .242-.45.069-.97"
            transform="translate(0 3.3)"
          />
          <path
            fill="#000"
            d="M10.124 5.703c.123.089.291.241-.056.217 0 0-.761-.139-1.142-.035 0 0-.622.104-1.141.623 0 0 .138-.242.45-.52 0 0 .62-.62 1.701-.37.068.016.132.045.188.085M12.04 5.262s-.519.277-.138.312c0 0 .415-.07.553-.104 0 0 .623-.139 1.073 0 0 0 .657.207.9.484 0 0-.312-.484-.935-.692 0 0-.968-.311-1.453 0M8.2 8.134s.104.277.83.277c0 0 .692 0 .969-.173l-1.211.103s-.208-.138-.242-.138c-.035 0-.208-.035-.208-.035L8.2 8.134z"
            transform="translate(0 3.3)"
          />
          <path
            fill="#FFF"
            d="M8.13 7.96s0 .278.485.381c0 0 .657.104 1.142-.034 0 0 .415-.104.45-.208 0 0-.035-.588-.139-.726 0 0-.207-.38-.623-.415-.109-.01-.934-.139-1.141.31 0 0-.208.485-.173.693"
            transform="translate(0 3.3)"
          />
          <path
            fill="#020000"
            d="M8.338 7.407c.277-.588 1.177-.38 1.177-.38.622.173.657.726.657.726 0-.207-.07-.415-.07-.415-.138-.346-.726-.45-.726-.45-.553-.103-.969.104-.969.104-.45.277-.276.969-.276.969"
            transform="translate(0 3.3)"
          />
          <path
            fill="#000"
            d="M8.961 7.234s.208.104.173.277c0 0-.035.311-.415.138 0 0-.171.524.381.73 0 0 .622.032.76-.141 0 0 .208-.485-.034-.83 0 0-.346-.589-.865-.174M13.943 7.96s-.104.278-.83.278c0 0-.727.069-1.004-.104l1.246.034s.208-.138.242-.138c.035 0 .208-.035.208-.035l.138-.034z"
            transform="translate(0 3.3)"
          />
          <path
            fill="#FFF"
            d="M14.012 7.788s0 .271-.47.377c-.01.003-.019.004-.029.005-.118.013-.921.092-1.369-.036 0 0-.085-.017-.147-.087-.043-.047-.062-.112-.057-.176.012-.15.05-.559.135-.671 0 0 .207-.38.623-.415.109-.01.934-.139 1.141.31 0 0 .208.485.173.693"
            transform="translate(0 3.3)"
          />
          <path
            fill="#020000"
            d="M13.805 7.234c-.277-.588-1.177-.38-1.177-.38-.622.173-.657.726-.657.726 0-.207.07-.415.07-.415.138-.346.726-.45.726-.45.553-.103.969.104.969.104.45.277.276.969.276.969"
            transform="translate(0 3.3)"
          />
          <path
            fill="#000"
            d="M13.182 7.061s-.208.104-.173.277c0 0 .035.311.415.139 0 0 .171.523-.381.729 0 0-.622.032-.76-.141 0 0-.208-.485.034-.83 0 0 .346-.589.865-.174"
            transform="translate(0 3.3)"
          />
          <path
            fill="#B26A50"
            d="M10.553 9.622s-.07.103 0 .173c0 0 .38.207.519.207 0 0 .242-.069.415-.242 0 0 .173-.104.207-.07v-.138h-.346s-.346-.034-.38-.034c-.035 0-.208.069-.208.069l-.207.035z"
            transform="translate(0 3.3)"
          />
          <path
            fill="#EA9776"
            d="M10.795 8.826s-.242.519-.242.726v.07s.346.103.415.138c0 0 .173.035.311-.07 0 0 .277-.172.415-.103 0 0-.069-.346-.207-.554 0 0-.139-.484-.173-.76 0 0-.173-.347-.38 0 0 0-.07.38-.14.553"
            transform="translate(0 3.3)"
          />
          <path
            fill="#5C323A"
            d="M9.93 10.694s-.035-.138-.173.07c0 0-.139.207.104.069 0 0 .459.137.593.328l.48.502.518.035.45-.139.207-.207.243-.277.202-.214.074-.063s.139-.208.312-.104c0 0-.07-.104-.208-.104l-.204.06-.419.113-.519-.034-.484.034h-.311l-.242.07h-.346s-.173-.035-.277-.139"
            transform="translate(0 3.3)"
          />
          <path
            fill="#B5524A"
            d="M10.207 10.97s.276.347.346.485c.069.139.276.416.622.416 0 0 .554.034.796-.243 0 0 .346-.346.484-.553 0 0 .139-.208.173-.277 0 0-.207.104-.31.208 0 0-.278.31-.382.38 0 0-.31.277-.726.242 0 0-.38-.069-.52-.276 0 0-.345-.346-.483-.381"
            transform="translate(0 3.3)"
          />
          <path
            fill="#FFF"
            d="M11.469 10.833s-.813.034-.951.138c0 0 .242.208.623.208 0 0 .553.069.83-.208 0 0 .138-.138.208-.173 0 0-.043.004-.42.02"
            transform="translate(0 3.3)"
          />
          <path
            fill="#B5524A"
            d="M10.168 10.823s.212-.094.42-.163c0 0 .449-.243 1.072-.07 0 0 .59.13.849.065l-.33.143s-1.488.035-1.661.173c0 0-.25.015-.35-.148"
            transform="translate(0 3.3)"
          />
          <path
            fill="#F3A681"
            d="M10.345 13.185s.208.07-.07 1.488c0 0-.31.969-.276 1.246 0 0 .07.138.45.138 0 0 2.041.07 2.49 0 0 0 .347-.07.416-.138 0 0 .035-.727-.07-.97l-.103-.345s-.208-1.315-.07-1.869c0 0-.742.684-1.418.83 0 0-.795.035-1.35-.38"
            transform="translate(0 3.3)"
          />
          <path
            fill="#D97F5C"
            d="M7.439 9.587s.276 1.073.622 1.488l1.834 1.937s-1.488-.969-2.283-2.11c0 0-.173-1.177-.173-1.315"
            transform="translate(0 3.3)"
          />
          <path
            fill="#F49976"
            d="M13.272 14.904c-.083-.22-.142-.69-.142-.69-.134-.842-.05-1.355-.024-1.482-.148.121-.697.526-.697.526-.6.453-1.372.239-1.372.239l1.28 2.595.013-.007-.002.003c.91-.004 1.027-.17 1.027-.17-.035-1.003-.083-1.014-.083-1.014"
            transform="translate(0 3.3)"
          />
          <path
            fill="#F49976"
            d="M13.122 12.74l-.016.075.067-.057c.196-.182-.05-.017-.05-.017"
            transform="translate(0 3.3)"
          />
          <path
            fill="#D97F5C"
            d="M14.531 9.342s-.103 1.283-.311 1.56c0 0-.761 1.038-1.142 1.453 0 0-.726.865-1.038 1.003 0 0-.277.173-.38.208 0 0 .415.138 1.453-.83 0 0 .626-.812.936-1.168 0 0 .366-.459.459-.978 0 0-.003-1.161.023-1.248"
            transform="translate(0 3.3)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
